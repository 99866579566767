<template>
  <a-card :bordered="false">
    <a-row :gutter="8">
      <a-col :span="24">
        <div class="table-page-search-wrapper">
          <a-form layout="inline" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
            <a-row :gutter="8">
              <a-col :xxl="6" :xl="6" :md="8" :sm="12">
                <a-form-item label="用户名">
                  <a-input placeholder="请输入" v-model="queryParam.loginName" allowClear/>
                </a-form-item>
              </a-col>
              <a-col :xxl="6" :xl="6" :md="8" :sm="12">
                <a-form-item label="部门">
                  <a-tree-select
                    v-model="queryParam.deptId"
                    :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                    :treeData="depts"
                    treeDefaultExpandAll
                    @change="handleChangeDept"
                    placeholder="部门"
                    allowClear
                  >
                  </a-tree-select>
                </a-form-item>
              </a-col>
              <a-col :xxl="6" :xl="6" :md="8" :sm="12">
                <a-form-item label="状态">
                  <a-select placeholder="请选择" v-model="queryParam.workStatus" allowClear>
                    <a-select-option :value="''">全部</a-select-option>
                    <a-select-option :value="0">禁用</a-select-option>
                    <a-select-option :value="1">启用</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :xxl="6" :xl="6" :md="8" :sm="12">
                <span class="table-page-search-submitButtons">
                  <a-button type="primary" @click="getList(0)">查询</a-button>
                  <a-button style="margin-left: 8px" @click="getList(1)">重置</a-button>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </a-col>
    </a-row>
    <div class="table-operator">
      <a-button v-if="addEnable" type="primary" icon="plus" @click="onAdd()">新建</a-button>
      <a-dropdown v-if="removeEnable && selectedRowKeys.length > 0">
        <a-button type="danger" icon="delete" @click="delByIds(selectedRowKeys)">删除</a-button>
      </a-dropdown>
    </div>
    <a-table
      size="default"
      ref="table"
      rowKey="id"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :columns="columns"
      :data-source="tableList"
      :pagination="page"
      :rowClassName="rowClassName"
      @change="changeTable"
    >
      <span slot="workStatus" slot-scope="text">
        {{ text | typeFilter }}
      </span>
      <span slot="roles" slot-scope="text">
        <span v-for="(item, index) in text" :key="index">{{ item.name }}</span>
      </span>
      <span slot="login.flagEnable" slot-scope="text, record">
        <a-switch v-model="record.login.flagEnable" @change="onChangeStatus(record)" />
      </span>
      <span slot="workStatus" slot-scope="text">
        <a-badge status="warning" v-if="text == 1" text="试用期" />
        <a-badge status="success" v-if="text == 2" text="已转正" />
        <a-badge status="error" v-if="text == 3" text="已离职" />
      </span>
      <span slot="action" slot-scope="text, record">
        <a v-if="editEnabel" @click="handleEdit(record)">编辑</a>
<!--        <a-divider type="vertical" />-->
<!--        <a v-if="relationDealerEnable" @click="handleRelation(record)">关联经销商</a>-->
        <a-divider type="vertical" />
        <a v-if="removeEnable" @click="delByIds([record.id])">删除</a>
        <a-divider type="vertical" />
        <a v-if="resetPwdEnabel" @click="resetPwd(record)">重置密码</a>
      </span>
    </a-table>
    <user-modal ref="modal" @ok="getList(1)" :deptTree="deptTree" :cities="cities" />
    <user-pwd-modal ref="pwdmodal" />
    <link-dealer ref="linkDealer" @reload="getList"></link-dealer>
  </a-card>
</template>

<script>
import { STable, SearchTree } from '@/components'
import { getUserList, getDeptListEnable, delUser, changUserStatus, listCity } from '@/api/system'
import UserModal from './modules/UserModal'
import UserPwdModal from './modules/UserPwdModal'
import linkDealer from './modules/linkDealer.vue'
import pick from 'lodash.pick'
import { checkPermission } from '@/utils/permissions'

export default {
  name: 'UserList',
  components: {
    SearchTree,
    STable,
    UserModal,
    UserPwdModal,
    linkDealer
  },
  data() {
    return {
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      // description: '列表使用场景：后台管理中的权限管理以及角色管理，可用于基于 RBAC 设计的角色权限控制，颗粒度细到每一个操作类型。',
      visible: false,
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 5,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 16,
        },
      },
      mdl: {},
      // 高级搜索 展开/禁用
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '登录名',
          dataIndex: 'login.loginName',
        },
        {
          title: '昵称',
          dataIndex: 'login.nickName',
        },
        {
          title: '所属部门',
          dataIndex: 'department.name',
        },
        {
          title: '在职状态',
          dataIndex: 'workStatus',
          scopedSlots: { customRender: 'workStatus' },
        },
        {
          title: '是否启用',
          dataIndex: 'login.flagEnable',
          scopedSlots: { customRender: 'login.flagEnable' },
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          sorter: true,
        },
        {
          title: '操作',
          width: '180px',
          dataIndex: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      tableList: [],
      deptTree: [],
      expandedKeys: [],
      dataList: [],
      holderText: '搜索部门',
      selectedRowKeys: [],
      selectedRows: [],
      cities: [],
      addEnable: checkPermission('system:user:add'),
      editEnabel: checkPermission('system:user:edit'),
      resetPwdEnabel: checkPermission('system:user:resetPwd'),
      removeEnable: checkPermission('system:user:remove'),
      relationDealerEnable: checkPermission('system:user:relation_dealer'),
      deptList: [],
      depts: [],
    }
  },
  filters: {
    typeFilter(type) {
      const typeMap = {
        1: '试用期',
        2: '已转正',
        3: '已离职',
      }
      return typeMap[type]
    },
  },
  created() {
    // listCity().then(res => {
    //   this.cities = res.body
    // })
    // getDeptListEnable().then(res => {
    //   const data = res.body
    //   this.buildtree(data, this.deptTree, 0)
    //   this.expandedKeys = data.map(m => m.parentId)
    //   this.dataList = data.map(m => {
    //     return {
    //       key: m.id,
    //       title: m.name
    //     }
    //   })
    // })
    this.getList()
    this.getDeptList()
  },
  methods: {
    // 关联经销商
    handleRelation(row) {
      this.$refs.linkDealer.isShow(row)
    },
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    getList(num) {
      this.selectedRowKeys = []
      this.selectedRows = []

      if (num == 1) {
        this.queryParam = {}
      }
      let obj = {
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
      }
      let params = Object.assign({}, obj, this.queryParam)
      getUserList(params).then((res) => {
        this.tableList = res.body.records
        this.page.total = res.body.total
      })
    },
    getDeptList() {
      this.axios.get('/api/base/system/department/all').then((res) => {
        const data = res.body
        this.buildtree(data, this.depts, 0)
      })
    },
    buildtree(list, arr, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          var child = {
            key: item.id,
            value: item.id, // value是给modal的select用的，2者属性不一样
            title: item.name,
            scopedSlots: { title: 'title' },
            children: [],
          }
          this.buildtree(list, child.children, item.id)
          if (child.children.length === 0) {
            delete child.children
          }
          arr.push(child)
        }
      })
    },
    handleChangeDept(value, label, extra) {
    },
    // 分页
    changeTable(pagination) {
      this.page = pagination
      this.getList()
    },
    onAdd() {
      this.$refs.modal.isShow(null, 'add')
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleEdit(record) {
      this.$refs.modal.isShow(record, 'edit')
    },
    resetPwd(record) {
      this.$refs.pwdmodal.edit(record)
    },
    onChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleOk() {
      this.$refs.table.refresh()
    },
    delByIds(ids) {
      const that = this
      this.$confirm({
        title: '警告',
        content: `真的要删除选中用户吗?`,
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          // 在这里调用删除接口
          delUser({
            ids: ids.join(','),
          }).then((res) => {
            if (res.code === 200) {
              that.getList()
              that.$message.success(`删除成功`)
            } else {
              that.$message.error(res.msg)
            }
            // const difference = new Set(this.selectedRowKeys.filter(x => !new Set(ids).has(x)))
            // this.selectedRowKeys = Array.from(difference)
            that.selectedRowKeys = []
          })
        },
        onCancel() {
          // console.log('Cancel')
        },
      })
    },
    onChangeStatus(row) {
      this.axios
        .post('/api/base/system/manager/status/' + row.id)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success(res.message)
            this.getList(1)
          }
        })
        .catch((err) => {
          this.getList(1)
        })
    },
    buildtree(list, arr, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          var child = {
            key: item.id,
            value: item.id, // value是给modal的select用的，2者属性不一样
            title: item.name,
            scopedSlots: {
              title: 'title',
            },
            children: [],
          }
          this.buildtree(list, child.children, item.id)
          if (child.children.length === 0) {
            delete child.children
          }
          arr.push(child)
        }
      })
    },
    // 下面是树相关方法
    handleSelect(selectedKeys, info) {
      this.queryParam = {
        departmentId: selectedKeys[0],
      }
      this.$refs.table.refresh(true)
    },
  },
  watch: {
    /*
        'selectedRows': function (selectedRows) {
          this.needTotalList = this.needTotalList.map(item => {
            return {
              ...item,
              total: selectedRows.reduce( (sum, val) => {
                return sum + val[item.dataIndex]
              }, 0)
            }
          })
        }
        */
  },
}
</script>

<style scoped>
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
</style>
