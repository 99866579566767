<template>
  <div>
    <a-modal title="选择经销商" v-model="visible" width="30%" :footer="null" :maskClosable="false">
      <div class="realtor">
        <!--        <a-form-model ref="searchData" :label-col="{xs:7}" :wrapper-col="{xs:17}">
                  <a-row :gutter="8">
                    <a-col :md="8" :xl="6" :xxl="6" :sm="12">
                      <a-form-model-item label="事务所名称:">
                        <a-input v-model="searchData.dealerName" placeholder="请输入事务所名称"></a-input>
                      </a-form-model-item>
                    </a-col>
                    <a-col :md="8" :xl="6" :xxl="6" :sm="12">
                      <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
                        <a-button type="primary" @click="getList(0)" icon="search">查询</a-button>
                        <a-button type="default" @click="getList(1)" icon="sync">重置</a-button>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                </a-form-model>-->
        <a-table
          :data-source="tableData"
          :columns="columns"
          :loading="tableLoading"
          :rowKey="(record) => record.id"
          :pagination="page"
          bordered
          @change="changeTable"
          :row-selection="rowSelection"
        >
          <span slot="name" slot-scope="text,row"> 【{{row.firmName}}】{{ row.name }} </span>
        </a-table>
      </div>
      <div class="footer-btns">
        <a-button @click="onCancel">取消</a-button>
        <a-button type="primary" :loading="btnsLoading" @click="onSubmit">确定</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {

  data() {
    return {
      visible: false,
      searchData: {},
      managerId:'',
      tableLoading: false,
      tableData: [],
      columns: [
        {
          title: '经销商',
          dataIndex: 'name',
          // width: 200,
          // ellipsis: true,
          align: 'left',
          scopedSlots: { customRender: 'name' },
        },
      ],
      page: {
        pageNumber: 1,
        pageSize: 10,
        total: 0
      },
      selectedRowKeys: [],
      selectedRows: [],
      btnsLoading: false,
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        // type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    // 打开弹框触发
    isShow(row) {
      this.visible = true
      this.managerId = row.id
      let managerId = this.managerId
      this.axios.get('/api/dealer/dealer/dealerInfo/selectLinkgapBearsByManagerId?managerId=' + managerId).then(res => {
        if (res.code === 200) {
          this.selectedRowKeys = res.body.dealerIds || [];
          console.log(res.body.dealerIds)
        }
      })
      this.getList()
    },

    changeTable(pagination) {
      this.page = pagination
      this.page.pageNumber = pagination.current
      this.getList()
    },
    getList() {
      this.axios.get('/api/dealer/dealer/dealerInfo/listLinkgapManagerDealer').then(res => {
        if (res.code === 200) {
          this.tableData = res.body
          this.tableLoading = false
        }
      })
    },

    // 关闭弹框
    onCancel() {
      this.visible = false
      this.selectedRowKeys = []
      this.selectedRows = []
      this.page.pageNumber = 1
      this.page.pageSize = 10
    },
    onSubmit() {
      // if (this.selectedRowKeys.length === 0) {
      //   return this.$message.warning('请至少选择一条数据')
      // }
      let ids = []
      for(var i = 0;i<this.selectedRowKeys.length;i++){
        if(ids.indexOf(this.selectedRowKeys[i]) === -1){
          ids.push(this.selectedRowKeys[i]);
        }
      }
      let data = {
        managerId: this.managerId,
        dealerIds: ids,
      }
      console.log(data)
      this.btnsLoading = true
      this.axios.post('/api/dealer/dealer/dealerInfo/editLinkgapManagerDealer', data).then(res => {
        if (res.code === 200) {
          this.$message.success(res.message)
          this.btnsLoading = false
          this.visible = false
          this.page.pageNumber = 1
          this.page.pageSize = 10
          this.$emit('reload')
        }
      })
    },
  }
}
</script>

<style scoped>
.footer-btns {
  text-align: right;
  border-top: 1px solid #e8e8e8;
  padding-top: 20px;
  margin-top: 20px;
}
</style>